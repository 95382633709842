<template>
  <div>
    <b-row class="m-0">

      <b-col cols="12" class="tab-card">
        <b-tabs>
          <b-tab
            v-for="item in tabs"
            :key="'dyn-tab-' + item.id"
            :title="item.title"
            @click="tab = item.title"
          >
            <b-row class="m-0">
              <b-col
              sm="12"
              md="6"
              v-for="(tabValue, index) in packages"
              :key="index"
              v-if="tabValue.status === item.title"
              >
                <div>
                  <b-card class="p-0">
                    <b-card-header class="h1 py-2 d-flex pt-0">
                      <b-card-title class="font-weight-bolder h1 d-flex align-item-center justify-content-between w-100"
                        >
                        <div class="d-flex">
                          {{tabValue.title}}
                          <b-badge class="ml-1 case-badge">{{tabValue.segment}}</b-badge>
                        </div>
                        <feather-icon
                        @click="copyLink(tabValue.permaLink)"
                        class="cursor-pointer"
                          icon="Link2Icon"
                          size="20"
                        />
                      </b-card-title>
                      <div class="d-flex align-items-center">
                        <!-- <b-form-checkbox
                          switch
                          class="ml-1"
                          @change="togglevalue($event, tabValue.uuid)"
                        ></b-form-checkbox> -->
                      </div>
                    </b-card-header>
                    <b-card-body class="p-0">
                      <div class="d-flex mb-1">
                        <div class="mr-3">
                          <p class="font-weight-bolder mb-0 font-base">
                            Risk level
                          </p>
                          <span>{{tabValue.riskProfile}}</span>
                        </div>
                        <div class="">
                          <p class="font-weight-bolder mb-0 font-base">
                            Minimum capital required
                          </p>
                          <span>{{tabValue.minCapital}} INR</span>
                        </div>
                      </div>
                      <!-- <div class="mb-1">
                        <p class="font-weight-bolder mb-0 font-base">
                          Ideak for :
                        </p>
                        <span>Ideak for long tearm term investor</span>
                      </div> -->
                      <div class="mb-1">
                        <p class="font-weight-bolder mb-0 font-base">
                          Discription
                        </p>
                        <span>{{ tabValue.description }}</span>
                      </div>
                      <div class="mb-1">
                        <p class="font-weight-bolder mb-0 font-base">Details</p>
                        <p class="mb-0">{{ tabValue.details }}</p>
                        <!-- <p class="mb-0">2 Fundamental calls every 15 days</p>
                        <p class="mb-0">Suitable for Long Term investors</p>
                        <p class="mb-0">Low risk Low return calls</p>
                        <p class="mb-0">You must enter exit as per our updates</p> -->
                      </div>
                      <div>
                        <b-row>
                          <b-col sm="12" md="3" class="mb-1" v-for="(option, index) in tabValue.payOptions" :key="index">
                            <p class="font-weight-bolder mb-0 font-base">{{option.title.replace('_', ' ')}}</p>
                            <span class="price d-block">₹ {{option.price}}</span>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="d-flex justify-content-end" v-if="item.title == 'active'">
                        <b-btn
                          pill
                          variant="primary"
                          class=""
                          @click="openModal(tabValue)"
                          v-b-modal.modal-no-backdrop
                        >
                          Create Recommendation
                        </b-btn>
                      </div>
                    </b-card-body>
                  </b-card>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <recommendation-model v-if="recommendationData" :recommendationData="recommendationData" :packagesList="packages.filter(el => el.status === tab)" />
  </div>
</template>
  
<script>
import { ref, watch, onMounted, inject, reactive } from "@vue/composition-api";
import InputField from "@/views/components/form/InputField.vue";
import SelectField from "@/views/components/form/SelectField.vue";
import DateField from "@/views/components/form/DateField.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RecommendationModel from '../RecommendationModel.vue'
import Vue from 'vue'

/* eslint-disable global-require */
export default {
  components: {
    SelectField,
    InputField,
    DateField,
    RecommendationModel
  },
  setup() {
    const $http = inject("$http");
    const recommendationData = ref(null)
    const tab = ref('active')
    const tabs = [
      { id: 1, title: "active" },
      { id: 2, title: "suspended" },
      // { id: 2, title: "draft" },
      // { id: 3, title: "inactive" },
    ];

    let packages = ref([]);

    onMounted(() => {
      getPackage();
    });

    const getPackage = async () => {
      await $http
        .get(`/advisor/packages`)
        .then((response) => {
          packages.value = response.data.packages;
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          });
        });
    };

    const togglevalue = async (val, id) => {
      const data = {
        id: id,
        status: val ? "live" : "deleted",
      };
      await $http
        .put(`/advisor/packages`, data)
        .then((response) => {
          console.log(response, "response");
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          });
        });
    };

    const openModal= (val) => {
      console.log(val,'val')
      recommendationData.value = val
    }

    const getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    const copyLink= (link) => {
      const tempInput = document.createElement('input');
      tempInput.setAttribute('value', link);
      document.body.appendChild(tempInput);

      // Select the input element's content
      tempInput.select();
      tempInput.setSelectionRange(0, 99999); // For mobile devices

      // Copy the selected content to the clipboard
      document.execCommand('copy');

      // Remove the temporary input
      document.body.removeChild(tempInput);
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: `Success`,
          icon: "AlertCircleIcon",
          variant: "success",
          text:'Link copied to clipboard!',
          closeOnClick: false,
          timeout: false,
        },
      });
    }
    return {
      packages,
      tabs,
      togglevalue,
      getValidationState,
      copyLink,
      recommendationData,
      openModal,
      tab
    };
  },
};
</script>

<style lang="scss">
.tab-card .nav-tabs {
  text-transform: capitalize;
}

.case-badge {
  border-radius: 4px;
  font-size: 14px;
  background: rgba(37, 99, 235, 0.12);
  color: #404966;
  line-height: 18px;
}

.price {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: #31609d;
  margin-top: 6px;
}
</style>